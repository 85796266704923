import axios from "axios";
import { addSignatureFn } from "./interceptors";
import { UTM_SOURCE } from "@common/constants/constants";

axios.defaults.withCredentials = true;

const ApiService = {};

// ApiService.head = (url, options) => {
//   return axios.head(url, {
//     headers: options.headers,
//     params: options.params
//     //paramsSerializer: params => {
//     //            return transformRequestOptions(params);
//     //        }
//   });
// };

ApiService.get = (url, options = {}) => {
    return axios.get(url, {
        withCredentials: options.withCredentials,
        params: options.params,
        headers: options.headers,
        // paramsSerializer: params => {
        //            return transformRequestOptions(params);
        //        }
    });
};

ApiService.post = (url, options = {}) => {
    return axios.post(url, options.data, {
        headers: options.headers,
        params: options.params,
    });
};

ApiService.put = (url, options) => {
    return axios.put(url, options.data, {
        headers: options.headers,
        params: options.params,
    });
};

ApiService.patch = (url, options = {}) => {
    return axios.patch(url, options.data, {
        headers: options.headers,
        params: options.params,
    });
};

ApiService.delete = (url, options = {}) => {
    return axios.delete(url, {
        data: options.data,
        headers: options.headers,
        params: options.params,
    });
};

ApiService.axios = (options) => {
    return axios(options);
};
// ApiService.getMisc = (url, options) => {
//   return axiosMisc.get(url, {
//     params: options.params,
//     headers: options.headers
//     //paramsSerializer: params => {
//     //            return transformRequestOptions(params);
//     //        }
//   });
// };

// ApiService.postMisc = (url, options) => {
//   return axiosMisc.post(url, options.data, { headers: options.headers });
// };

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (
            401 === (error.response && error.response.status)
            // || 403 === (error.response && error.response.status)
        ) {
            if (
                typeof window !== "undefined" &&
                !error.response.config.url.includes("authentication") &&
                !error.response.config.url.includes("session")
            ) {
                window.location.pathname = `/auth/login?utm_source=${UTM_SOURCE}`;
                // setTimeout(() => {
                //   window.location.pathname = "/auth/login";
                // }, 500);
            }
        }
        return Promise.reject(error);
    },
);

axios.interceptors.request.use(addSignatureFn({}));

export default ApiService;
